import React, { useContext } from "react";
import { paramCase, camelCase } from "change-case";
import { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import IndustryContainer from "../../containers/IndustryContainer";
import Image from "../Image";
import HeroBanner from "../HeroBanner";
import Box from "../../base/Box";
import MarkdownToHtml from "../MarkdownToHtml";
import Section from "../Section";
import CaseStudyResults from "./CaseStudyResults";
import AspectRatio from "../AspectRatio";
import Icon from "../Icon";
import ServicesContainer from "../../containers/ServicesContainer";
import ServiceCard from "./components/ServiceCard";
import CarousalContainer from "./components/CarousalContainer";
import RelatedProjectCard from "./components/RelatedProjectCard";

const CaseStudyLayout = ({ pageContext }) => {
  const theme = useContext(ThemeContext);

  const {
    projectData: { id },
  } = pageContext;

  const commonGap = 4;
  const paragraphSx = { color: "black" };

  let shouldHideServices = false;
  const containerWidth = [
    "calc(100% - 3rem)",
    null,
    null,
    "calc(100% - 5.5rem)",
  ];

  return (
    <>
      <IndustryContainer>
        {(_, allProjectsData, __, allIndustriesListJson) => {
          const {
            aboutCaseStudy = {},
            caseStudyDetails = {},
            caseStudyServices = {},
            caseStudyResults = {},
            heroContent: { backgroundImage = "", logo = "" } = {},
            screenshots = [],
            testimonial = {},
            hideServices = false,
            projectId = "",
            moreProjects = {},
          } = allProjectsData.find((i) => paramCase(i.id) === id);
          shouldHideServices = hideServices;

          const allRelatedProjectIDs = allIndustriesListJson
            .filter(({ featuredProjects }) =>
              featuredProjects.includes(projectId)
            )
            // Get related projects to current project from all industries
            .map(({ featuredProjects }) => featuredProjects);

          const flattenRelatedProjectIDs = allRelatedProjectIDs
            .flatMap((item) => item.split(","))
            .map((item) => item.trim());

          const relatedProjectIDs = [
            ...new Set(flattenRelatedProjectIDs),
          ].filter((id) => id !== projectId);

          const relatedProjectsData = allProjectsData.filter((caseStudy) =>
            relatedProjectIDs.includes(caseStudy.projectId)
          );

          // TODO: Add more industry section
          const hideRelatedProjects = true;

          return (
            <>
              <HeroBanner
                bannerImage={backgroundImage}
                containerSx={{
                  mb: "auto",
                  pb: [5, null, null, null, 6],
                  position: "relative",
                  pt: [5, null, null, null, 6],
                }}
                sx={{
                  "&:before": {
                    backgroundImage: theme.baseGradients.grays[2],
                    bottom: "0",
                    content: "''",
                    left: "0",
                    position: "absolute",
                    right: "0",
                    top: "0",
                  },
                }}
              >
                <Image
                  img={logo}
                  sx={{
                    cursorPinter: "none",
                    height: "auto",
                    ml: [1, null, null, 5],
                    width: ["15rem", null, null, "20rem"],
                  }}
                />
              </HeroBanner>
              <Section
                containerSx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: commonGap,
                  width: containerWidth,
                }}
                sx={{ pb: [1, null, 6] }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: ["column", null, null, "row"],
                    gap: commonGap,
                  }}
                >
                  <Box sx={{ width: ["100%", null, null, "60%"] }}>
                    <MarkdownToHtml
                      contentSx={{ p: paragraphSx }}
                      heading={aboutCaseStudy.heading}
                      source={aboutCaseStudy.content}
                    />
                    <MarkdownToHtml
                      contentSx={{
                        li: {
                          pt: [1, null, null, 2],
                        },
                        ul: { ...paragraphSx },
                      }}
                      heading={caseStudyServices.heading}
                      source={caseStudyServices.content}
                      sx={{ mt: commonGap }}
                    />
                  </Box>
                  <Box sx={{ width: ["100%", null, null, "40%"] }}>
                    <CaseStudyResults results={caseStudyResults} />
                  </Box>
                </Box>
                <MarkdownToHtml
                  contentSx={{ p: paragraphSx }}
                  heading={caseStudyDetails.heading}
                  source={caseStudyDetails.content}
                  sx={{ mt: commonGap }}
                />
              </Section>
              {screenshots.length > 0 && (
                <Section containerSx={{ width: containerWidth }} sx={{ pt: 0 }}>
                  <Box
                    sx={{
                      display: ["none", null, "flex"],
                    }}
                  >
                    <CarousalContainer settings={{ slidesToShow: 1 }}>
                      {screenshots.map((item, index) => (
                        <Box
                          key={`${camelCase(item.image)}-${index}`}
                          sx={{ px: 2 }}
                        >
                          <AspectRatio
                            ratio={[730, 350]}
                            sx={{
                              maxWidth: "100%",
                              minWidth: "33.33%",
                            }}
                          >
                            <Image
                              img={item.image}
                              sx={{
                                height: "auto",
                                objectFit: "cover",
                                width: "100%",
                              }}
                            />
                          </AspectRatio>
                        </Box>
                      ))}
                    </CarousalContainer>
                  </Box>
                  <Box
                    sx={{
                      display: ["flex", null, "none"],
                      flexWrap: "wrap",
                      gap: 2,
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    {screenshots.map((item, index) => (
                      <Box
                        key={`${camelCase(item.image)}-${index}`}
                        sx={{
                          border: "1px solid",
                          borderColor: "primaryLight",
                          pb: 3,
                        }}
                      >
                        <Image
                          img={item.image}
                          sx={{
                            height: "auto",
                            objectFit: "cover",
                            width: "100%",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Section>
              )}
              {hideRelatedProjects &&
                relatedProjectsData.length > 0 &&
                !moreProjects?.hideProjects && (
                  <Section containerSx={{ width: containerWidth }}>
                    <MarkdownToHtml
                      heading={moreProjects.heading}
                      sx={{ mb: 4 }}
                    />
                    <Box>
                      <CarousalContainer settings={{ slidesToShow: 1 }}>
                        {relatedProjectsData.map((relatedProjectData) => (
                          <RelatedProjectCard
                            relatedProjectData={relatedProjectData}
                          />
                        ))}
                      </CarousalContainer>
                    </Box>
                  </Section>
                )}
              {!testimonial.hideTestimonial && (
                <Section
                  containerSx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    px: [0, null, null, 6],
                    width: containerWidth,
                  }}
                  sx={{
                    bg: "primary",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      position: "relative",
                    }}
                  >
                    <Icon
                      svg="quotes"
                      sx={{
                        color: "primaryLight",
                        display: ["none", null, "block"],
                        left: "-15%",
                        opacity: "0.4",
                        position: "absolute",
                        svg: {
                          height: "5rem",
                          width: "5rem",
                        },
                        top: "-5%",
                      }}
                    />
                    <MarkdownToHtml
                      heading={testimonial.heading}
                      sx={{
                        h2: { color: "white", textAlign: "center" },
                        mb: 4,
                      }}
                    />
                  </Box>
                  <MarkdownToHtml
                    content={testimonial.content}
                    sx={{ p: { color: "white" }, position: "relative" }}
                  />
                  <MarkdownToHtml
                    content={testimonial.author}
                    sx={{ mt: 2, p: { color: "white" } }}
                  />
                  <MarkdownToHtml
                    content={testimonial.role}
                    sx={{ p: { color: "white" } }}
                  />
                </Section>
              )}
            </>
          );
        }}
      </IndustryContainer>
      <ServicesContainer>
        {(_, allServicesListJson) => {
          const services = allServicesListJson;
          return (
            !shouldHideServices && (
              <Section containerSx={{ width: containerWidth }}>
                <MarkdownToHtml heading="## Our Services" sx={{ mb: 4 }} />
                <Box sx={{ display: ["none", null, null, null, "block"] }}>
                  <CarousalContainer settings={{ slidesToShow: 3 }}>
                    {services.map((service) => (
                      <ServiceCard serviceData={service} />
                    ))}
                  </CarousalContainer>
                </Box>
                <Box
                  sx={{
                    display: ["flex", null, null, null, "none"],
                    flexWrap: "wrap",
                    gap: 2,
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {services.map((service) => (
                    <ServiceCard
                      serviceData={service}
                      wrapperSx={{
                        width: "100%",
                      }}
                    />
                  ))}
                </Box>
              </Section>
            )
          );
        }}
      </ServicesContainer>
    </>
  );
};

CaseStudyLayout.propTypes = {
  pageContext: PropTypes.shape({
    projectData: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default CaseStudyLayout;
