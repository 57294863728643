import React from "react";
import PropTypes from "prop-types";
import Box from "../../../base/Box";
import MarkdownToHtml from "../../MarkdownToHtml";
import AspectRatio from "../../AspectRatio";
import Image from "../../Image";
import LinkTo from "../../LinkTo";

const RelatedProjectCard = ({ relatedProjectData }) => {
  const { heroContent, brief, title } = relatedProjectData;
  return (
    <LinkTo
      hoverSx={{
        bg: "primarySoft",
        boxShadow: 2,
        button: {
          bg: "primary",
          color: "white",
        },
      }}
      sx={{
        bg: "white",
        boxShadow: 1,
        button: {
          bg: "transparent",
          color: "primary",
        },
        display: "flex",
        flex: "auto",
        flexDirection: "column",
        height: "40rem",
        p: 3,
        pb: 6,
        position: "relative",
        transition: "all .45s",
        width: "35rem",
      }}
      to={brief?.actionButtons[0]?.buttonPath || "/"}
    >
      <Box sx={{ bottom: 0, p: 3, position: "absolute" }}>
        {heroContent?.logo && (
          <Image
            img={heroContent?.logo}
            sx={{
              cursorPinter: "none",
              height: "auto",
              ml: 5,
              width: "15rem",
            }}
          />
        )}

        <MarkdownToHtml
          content={`${brief.content.substring(0, 30)}...`}
          heading={title}
          sx={{
            h2: { color: "primary" },
            mt: 2,
            p: { color: "primary" },
          }}
        />
        <Box
          sx={{
            ":hover": {
              color: "primaryLight",
            },
            alignItems: "center",
            color: "white",
            display: "inline-flex",
            fontSize: 0,
            my: 3,
          }}
          variant="buttons.link"
        >
          Learn more
          <AspectRatio sx={{ color: "inherit", ml: 2, width: "2rem" }}>
            <Image svgIcon="arrow-right" />
          </AspectRatio>
        </Box>
      </Box>
    </LinkTo>
  );
};

export default RelatedProjectCard;

RelatedProjectCard.propTypes = {
  relatedProjectData: PropTypes.shape({
    brief: PropTypes.shape({
      actionButtons: PropTypes.arrayOf(),
      content: PropTypes.string,
    }),
    heroContent: PropTypes.shape({
      logo: PropTypes.shape({}),
    }),
    title: PropTypes.shape({}),
  }).isRequired,
};
