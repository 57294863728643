import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import MarkdownToHtml from "../MarkdownToHtml";
import Box from "../../base/Box";
import Icon from "../Icon";

const CaseStudyResults = ({ results = {} }) => {
  const { heading = "", statistics = [] } = results;
  const theme = useContext(ThemeContext);

  return (
    <Box
      sx={{
        background: [
          `linear-gradient(to right, ${theme.colors.white} 50%, ${theme.colors.primary} 50%)`,
          null,
          null,
          `linear-gradient(to right, ${theme.colors.white} 40%, ${theme.colors.primary} 40%)`,
        ],
        pb: 5,
        position: "relative",
        pt: [0, null, null, 5],
        width: ["100%", null, null, "110%"],
      }}
    >
      <MarkdownToHtml heading={heading} />
      <Box
        sx={{
          alignItems: ["center", null, null, "flex-start"],
          display: "flex",
          flexDirection: "column",
        }}
      >
        {statistics.length > 0 &&
          statistics.map(
            ({ detailValue = 0, title = "", resultIcon = "noIcon" }) => (
              <Box
                sx={{
                  bg: "white",
                  boxShadow: theme.shadows[1],
                  mt: 4,
                  p: 4,
                  width: "80%",
                }}
              >
                <MarkdownToHtml
                  heading={title}
                  headingSx={{ h3: { color: "primary" } }}
                />
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexWrap: "noWrap",
                  }}
                >
                  {resultIcon !== "noIcon" && (
                    <Icon
                      svg="arrow-up"
                      sx={{
                        color: "primary",
                        height: "3.5rem",
                        rotate: resultIcon === "downIcon" && "180deg",
                        svg: {
                          height: "3.5rem",
                        },
                      }}
                    />
                  )}
                  <Box sx={{ color: "primary", fontSize: 6 }}>
                    {detailValue}
                  </Box>
                </Box>
              </Box>
            )
          )}
      </Box>
    </Box>
  );
};

CaseStudyResults.propTypes = {
  results: PropTypes.shape({}),
};

CaseStudyResults.defaultProps = {
  results: {},
};

export default CaseStudyResults;
