import React from "react";
import { paramCase } from "change-case";
import PropTypes from "prop-types";
import Box from "../../../base/Box";
import MarkdownToHtml from "../../MarkdownToHtml";
import Button from "../../Button";
import AspectRatio from "../../AspectRatio";
import Image from "../../Image";
import LinkTo from "../../LinkTo";

const ServiceCard = ({ serviceData, wrapperSx }) => {
  const { summary, title } = serviceData;
  return (
    <LinkTo
      hoverSx={{
        boxShadow: 2,
        button: {
          color: "primaryLight",
        },
      }}
      sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${
          summary.backgroundImage || "/assets/data_science_banner.png"
        }')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        height: "40rem",
        justifyContent: "flex-end",
        position: "relative",
        width: "35rem",
        ...wrapperSx,
      }}
      to={`/services/${paramCase(title)}` || "/"}
    >
      <Box
        sx={{
          p: 3,
        }}
      >
        <MarkdownToHtml
          content={`${summary.content.substring(0, 30)}...`}
          heading={summary.heading}
          sx={{
            h2: { color: "white" },
            mt: 2,
            p: { color: "white" },
          }}
        />
        <Button
          sx={{
            alignItems: "center",
            color: "white",
            display: "inline-flex",
            fontSize: 0,
            my: 3,
          }}
          variant="buttons.link"
        >
          Learn more
          <AspectRatio sx={{ color: "inherit", ml: 2, width: "2rem" }}>
            <Image svgIcon="arrow-right" />
          </AspectRatio>
        </Button>
      </Box>
    </LinkTo>
  );
};

export default ServiceCard;

ServiceCard.propTypes = {
  serviceData: PropTypes.shape({
    summary: PropTypes.shape({
      backgroundImage: PropTypes.string,
      content: PropTypes.string,
      heading: PropTypes.string,
    }),
    title: PropTypes.shape({}),
  }).isRequired,
  wrapperSx: PropTypes.shape({}),
};

ServiceCard.defaultProps = {
  wrapperSx: {},
};
