import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const WorkContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allIndustriesListJson(
          filter: { title: { ne: "DEFAULT" } }
          sort: { fields: title, order: ASC }
        ) {
          edges {
            node {
              id
              title
              industryId
              hideIndustry
              summary {
                heading
                content
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              ctaSection {
                hideSection
                textContent {
                  heading
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              heroContent {
                heading
                content
                image
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              introduction {
                heading
                content
                images {
                  image
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              details {
                heading
                content
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              featuredProjects
              reviews {
                heading
                content
                image
                ratings
              }
            }
          }
        }
        allIndustryJson {
          edges {
            node {
              id
              pageId
              pageBanner {
                hideSection
              }
              introduction {
                hideSection
              }
              featuredProjects {
                hideSection
              }
              reviews {
                hideSection
              }
            }
          }
        }
        allProjectsListJson(filter: { title: { ne: "DEFAULT" } }) {
          edges {
            node {
              id
              title
              projectId
              hideProject
              brief {
                content
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              heroContent {
                backgroundImage
                logo
              }
              caseStudyDetails {
                heading
                content
              }
              aboutCaseStudy {
                heading
                content
              }
              caseStudyServices {
                heading
                content
              }
              caseStudyResults {
                heading
                statistics {
                  overviewValue
                  detailValue
                  title
                  resultIcon
                }
              }
              hideServices
              details {
                heading
              }
              projectImage {
                image
              }
              screenshots {
                image
              }
              testimonial {
                hideTestimonial
                heading
                content
                author
                role
              }
              moreProjects {
                hideProjects
                heading
              }
            }
          }
        }
        allClientsJson {
          edges {
            node {
              id
              pageTitle
              textContent {
                heading
                content
              }
              clients {
                logo
                clientName
                clientUrl
                testimonial
                authorName
                authorDesignation
              }
            }
          }
        }
      }
    `}
    render={({
      allIndustryJson: { edges: industryPageData },
      allProjectsListJson: { edges: allProjectsData },
      allClientsJson: { edges: clientsData },
      allIndustriesListJson: { edges: industriesData },
    }) =>
      children(
        industryPageData.map((e) => ({
          ...e.node,
        })),
        allProjectsData.map((e) => ({
          ...e.node,
        })),
        clientsData.map((e) => ({
          ...e.node,
        })),
        industriesData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

WorkContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default WorkContainer;
