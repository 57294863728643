import React from "react";
import PropTypes from "prop-types";
import CustomArrow from "../../Carousel/CustomArrow";
import Carousel from "../../Carousel";

const CarousalContainer = ({ children, settings, sx }) => {
  const prevStyled = {
    left: 0,
  };
  const nextStyled = {
    right: 0,
  };
  const controlStyled = {
    bg: "primary",
    color: "white",
    position: "absolute",
    top: "50%",
  };

  return (
    <Carousel
      settings={{
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2500,
        centerMode: true,
        infinite: true,
        nextArrow: <CustomArrow nextSx={nextStyled} sx={controlStyled} />,
        prevArrow: <CustomArrow prev prevSx={prevStyled} sx={controlStyled} />,
        slidesToScroll: 1,
        slidesToShow: 3,
        speed: 500,
        ...settings,
      }}
      sx={{
        "& > .custom-arrow": {
          opacity: 0,
          transition: "all 0.35s",
        },
        "&:hover > .custom-arrow": { opacity: 1 },
        "&:hover > .custom-arrow-next": { right: 2 },
        "&:hover > .custom-arrow-prev": { left: 2 },
        ...sx,
      }}
    >
      {children}
    </Carousel>
  );
};

CarousalContainer.propTypes = {
  children: PropTypes.shape({}).isRequired,
  settings: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

CarousalContainer.defaultProps = {
  settings: {},
  sx: {},
};

export default CarousalContainer;
